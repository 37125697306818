import React from 'react';
import {config} from '../config/config';
import {Container, Row, Col} from 'reactstrap';

//Our own components
import Footer from '../layouts/Footer';
import {useMessageDisplay} from '../common/useMessageDisplay';
import {FooterBX2023} from './FooterBX2023';
const logoUrl = config.get('logoUrl');

export function MainLayout({status, children, colSize = {md: {size: 6, offset: 3}, lg: {size: 4, offset: 4}}}) {
  useMessageDisplay({status});

  const aleTheme = window.appState.config.aleTheme;

  return (
    <>
      <div className="login-box box-wrapper">
        <div className="box-centered">
          <Container>
            <Row>
              <Col md={colSize.md} lg={colSize.lg} className="box-content">
                <div className="text-center">
                  <img className="logo-inline" src={logoUrl} alt={'...'} />
                </div>
                <div>{children}</div>
                {/*<div>*/}
                {/*  <MessageDisplay status={status} />*/}
                {/*</div>*/}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {aleTheme === 'bx2023' ? <FooterBX2023 /> : <Footer />}
    </>
  );
}
